import Vue from 'vue'
import NastCore from 'nast'
import settings from './_config'
import libs from './_libs'
import ui from './_ui'
import VueTheMask from 'vue-the-mask'
import components from 'components/Index'
// import { App, } from '@capacitor/app'

Vue.mixin({
  components,
})
Vue.use(ui)
Vue.use(VueTheMask)
NastCore.createApp(settings, libs)
