<!-- eslint-disable max-len -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="c-sidebar">
    <n-loader :loading="$var('load')" />
    <div class="danger" v-html="$var('message')"></div>
    <div class="logo-place">
      <div class="image-place">
        <img :src="imgLogo" alt="">
      </div>
    </div>
    <div class="sidebar-links">
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.index' }" :class="{ 'active': routeName === 'desktop.index' }"
        qa-id="main-page-statistics-page-open-button">
        <img class="icon" :src="routeName === 'desktop.index' ? imgStatsActive : imgStats" alt="">
        <p :class="{ 'active': routeName === 'desktop.index' }">Статистика</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.users' }" :class="{ 'active': routeName === 'desktop.users' }"
        qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.users' ? imgUsersActive : imgUsers" alt="">
        <p :class="{ 'active': routeName === 'desktop.users' }">Пользователи</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.limits' }"
        :class="{ 'active': routeName === 'desktop.limits' }">
        <img class="icon" :src="routeName === 'desktop.limits' ? imgUsersActive : imgUsers" alt="">
        <p :class="{ 'active': routeName === 'desktop.limits' }">Лимиты</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.groupLimits' }"
        :class="{ 'active': routeName === 'desktop.groupLimits' }" style="height: 100px; font-size: 14px">
        <img class="icon" :src="routeName === 'desktop.groupLimits' ? imgUsersActive : imgUsers" alt="">
        <p :class="{ 'active': routeName === 'desktop.groupLimits' }">Лимиты на исполнительные листы</p>
      </n-link>
      <n-link
        v-if="company.ttIntegration" class="sidebar-link" :to="{ name: 'desktop.workdays' }"
        :class="{ 'active': routeName === 'desktop.workdays' }" qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.workdays' ? visitsActive : visits" alt="">
        <p :class="{ 'active': routeName === 'desktop.workdays' }">Посещения</p>
      </n-link>
      <n-link
        v-if="company.ttIntegration" class="sidebar-link" :to="{ name: 'desktop.filials' }"
        :class="{ 'active': routeName === 'desktop.filials' }" qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.filials' ? filialsActive : filials" alt="">
        <p :class="{ 'active': routeName === 'desktop.filials' }">Филиалы</p>
      </n-link>
      <n-link
        v-if="company.needCivilLegal || company.needFCBVerification" class="sidebar-link"
        :to="{ name: 'desktop.eds' }" :class="{ 'active': routeName === 'desktop.eds' }"
        qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.eds' ? documentsActive : documents" alt="">
        <p :class="{ 'active': routeName === 'desktop.eds' }">Документы</p>
      </n-link>
      <n-link
        v-if="company.integrationType === 'EXCEL'" class="sidebar-link" :to="{ name: 'desktop.integration' }"
        :class="{ 'active': routeName === 'desktop.integration' }" qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.integration' ? integrationTabActive : integrationTab" alt="">
        <p :class="{ 'active': routeName === 'desktop.integration' }">Интеграция</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.support' }"
        :class="{ 'active': routeName === 'desktop.support' }" qa-id="main-page-support-page-open-button">
        <img class="icon" :src="routeName === 'desktop.support' ? imgSupportActive : imgSupport" alt="">
        <p :class="{ 'active': routeName === 'desktop.support' }">Служба поддержки</p>
      </n-link>

      <div class="calendar">
        <c-datepicker
          v-model="dateRange" class="my-date-picker" range lang="rus" placeholder="Период"
          position="left" />
        <div class="days">
          <div class="day">
            <div class="content">
              <div class="top">
                <p>{{ m[dateRange[0].getMonth()] }}</p>
                <p>{{ dateRange[0].getFullYear() }}</p>
              </div>
              <div class="bottom">
                <p>{{ dateRange[0].getDate() >= 10 ? dateRange[0].getDate() : '0' + dateRange[0].getDate() }}</p>
              </div>
            </div>
          </div>
          <div class="day">
            <div class="content">
              <div class="top">
                <p>{{ m[dateRange[1].getMonth()] }}</p>
                <p>{{ dateRange[1].getFullYear() }}</p>
              </div>
              <div class="bottom">
                <p>{{ dateRange[1].getDate() >= 10 ? dateRange[1].getDate() : '0' + dateRange[1].getDate() }}</p>
              </div>
            </div>
          </div>
        </div>
        <n-select
          class="excel-select" :data="branches" :value.sync="branch"
          placeholder="Департамент (Не оябзательно)" />
        <div class="buttons">
          <n-button class="user-default-button" qa-id="document-download-button" @click="downloadExcel('default')">
            Скачать документ
          </n-button>
          <n-button
            class="user-default-button" qa-id="transaction-report-download-button"
            @click="downloadExcel('magnum')">
            Отчет по транзакциям
          </n-button>
          <n-button
            v-if="company.resourceId" class="user-default-button" qa-id="report-payment-system-download-button"
            @click="downloadPitech">
            Отчет (Платежная система)
          </n-button>
          <n-button
            v-if="company.resourceId" class="user-default-button"
            qa-id="report-payment-system-excel-download-button" @click="downloadPitechExcel">
            Отчет (Платежная система) Excel
          </n-button>
          <n-button class="user-default-button" qa-id="document-download-button" @click="downloadUsersExcel('default')">
            Скачать текущую таблицу
          </n-button>
        </div>
      </div>
      <div class="side-bar-logout" @click="$app.auth.logout()"><img :src="imgLogout" alt="">Выйти</div>
    </div>
  </div>
</template>

<script>
import imgLogo from 'assets/logo.png'
import imgFaq from 'assets/sidebar/faq.svg'
import imgStats from 'assets/sidebar/stats.svg'
import imgSupport from 'assets/sidebar/support.svg'
import imgUsers from 'assets/sidebar/users.svg'
import imgFaqActive from 'assets/sidebar/faq-active.svg'
import imgStatsActive from 'assets/sidebar/stats-active.svg'
import imgSupportActive from 'assets/sidebar/support-active.svg'
import imgUsersActive from 'assets/sidebar/users-active.svg'
import imgLogout from 'assets/profile/logout.svg'
import imgTimeTracker from 'assets/mobile-appbar/mobile-time-tracker.svg'
import visits from 'assets/sidebar/visits.svg'
import visitsActive from 'assets/sidebar/visits-active.svg'
import filials from 'assets/sidebar/filial.svg'
import filialsActive from 'assets/sidebar/filial-active.svg'
import documents from 'assets/sidebar/documents.svg'
import documentsActive from 'assets/sidebar/documents-active.svg'
import integrationTab from 'assets/sidebar/documents.svg'
import integrationTabActive from 'assets/sidebar/documents-active.svg'

export default {
  name: 'CSidebar',
  data() {
    return {
      imgLogo,
      imgStats,
      imgFaq,
      imgSupport,
      imgUsers,
      imgStatsActive,
      imgFaqActive,
      imgSupportActive,
      imgUsersActive,
      imgLogout,
      imgTimeTracker,
      visits,
      visitsActive,
      filials,
      filialsActive,
      documents,
      documentsActive,
      integrationTab,
      integrationTabActive,
      branches: [],
      branch: {},
      m: {
        0: 'Январь',
        1: 'Февраль',
        2: 'Март',
        3: 'Апрель',
        4: 'Май',
        5: 'Июнь',
        6: 'Июль',
        7: 'Август',
        8: 'Сентябрь',
        9: 'Октябрь',
        10: 'Ноябрь',
        11: 'Декабрь',
      },
      company: {},
      dateRange: [
        new Date(new Date(new Date().setDate(1))),
        new Date(new Date().setDate(33 - new Date(new Date().getFullYear(), new Date().getMonth(), 33).getDate())),
      ],
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
  },
  watch: {},
  created() {
    this.loadBranches()
    this.loadCompany()
  },
  methods: {
    loadCompany() {
      this.$var('load', true)
      $api.companies.get($app.auth.user().selectedCompany).then((response) => {
        this.company = response.data.content
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadBranches() {
      this.$var('load', true)
      $api.branches.get($app.auth.user().selectedCompany).with('company').then((response) => {
        this.branches = $n.map(response.data.content, (e) => {
          return {
            value: e.id,
            title: e.title,
          }
        })
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    downloadExcel(template = 'default') {
      this.$var('load', true)
      const data = {
        from: $app.date.format(this.dateRange[0], 'date'),
        to: $app.date.format(this.dateRange[1], 'date'),
      }
      data.by = 'company'
      if (Object.keys(this.branch).length !== 0) {
        data.type = 'branch'
        data.branchId = this.branch.value
      } else {
        data.type = 'company'
      }
      data.template = template
      $api.companies.excel($app.auth.user().selectedCompany, data).then((response) => {
        const link = document.createElement('a')
        link.href = response.data.content.src
        link.download = response.data.content.name
        link.click()
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.$var('load', false)
      })
    }, downloadUsersExcel(template = 'default') {
      this.$var('load', true)
      console.log($app.date.format(this.dateRange[0], 'date'))
      console.log($app.date.format(this.dateRange[1], 'date'))
      const request = { from: $app.date.format(this.dateRange[0], 'date'), to: $app.date.format(this.dateRange[1], 'date'), }
      $api.companies.usersExcel($app.auth.user().selectedCompany).query(request).then((response) => {
        const link = document.createElement('a')
        link.href = response.data.src
        link.download = response.data.name
        link.click()
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    downloadPitech() {
      this.$var('load', true)
      const data = {
        from: $app.date.format(this.dateRange[0], 'date'),
        to: $app.date.format(this.dateRange[1], 'date'),
      }
      $api.companies.pitech($app.auth.user().selectedCompany, data).then((response) => {
        if (response.data.content.src) {
          const link = document.createElement('a')
          link.href = response.data.content.src
          link.download = response.data.content.name
          link.target = '_blank'
          link.click()
        }
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    downloadPitechExcel() {
      this.$var('load', true)
      const data = {
        from: $app.date.format(this.dateRange[0], 'date'),
        to: $app.date.format(this.dateRange[1], 'date'),
      }
      if (this.company.payoutProvider === 'woopay') {
        const request = { from: $app.date.format(this.fromDate, 'date'), to: $app.date.format(this.toDate, 'date'), }
        $api.companies.woopayHistory($app.auth.user().selectedCompany).query(request).then((response) => {
          if (response.data.src) {
            const link = document.createElement('a')
            link.href = response.data.src
            link.download = response.data.name
            link.click()
          }
        }).catch((response) => {
          this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $api.companies.pitechExcel($app.auth.user().selectedCompany, data).then((response) => {
          if (response.data.content.src) {
            const link = document.createElement('a')
            link.href = response.data.content.src
            link.download = response.data.content.name
            link.click()
          }
        }).catch((response) => {
          this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-sidebar {
  position: fixed;
  z-index: 10;
  height: 100vh;
  left: 0;
  border-bottom: 0;
  bottom: 0;
  top: 0;
  padding-left: 15px;
  min-width: 220px;
  background-color: #F1F5F8;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F5F8;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 20px;
    border: 1px solid #F1F5F8;
  }
  .buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;

    .n-button {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  .excel-select {
    width: 210px;
    margin: 10px 0;

    ::v-deep {
      input {
        font-size: 12px;
      }
    }
  }

  .my-date-picker {
    width: 30px;
    --v-calendar-border-color: #000000;
    --v-calendar-text-color: #000000;
    --v-calendar-datepicker-icon-color: var(--secondary);
    --v-calendar-input-border: 1px solid var(--secondary);
    --v-calendar-input-text-color: #000000;

    ::v-deep {
      .input-field {
        min-width: 210px !important;
      }
    }

    ::v-deep input {
      font-size: 10px;
      border-radius: 12px 12px 0 0 !important;
    }
  }

  .user-default-button {
    width: 210px;
    min-height: 44px;
    background-color: var(--primary);
    border-radius: 8px;
    color: #ffffff;
    font-size: 0.8em;
  }

  .calendar {
    position: relative;
    min-width: 210px;
    // min-height: 190px;
    margin-right: 15px;

    .days {
      background-color: #ffffff;
      width: 210px;
      border-radius: 0 0 12px 12px;
      border: 1px solid var(--secondary);
      display: flex;
      align-items: center;
      justify-content: center;

      .day {
        width: 100%;

        &:first-child {
          border-right: 1px solid var(--secondary);
        }

        .content {
          height: 100%;

          .top {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p:last-child {
              font-weight: normal;
              font-size: 0.8em;
            }

            p {
              color: #000000;
              margin: 0;
              font-size: 1em;
              font-weight: bold;
            }
          }

          .bottom {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              margin: 0;
              font-size: 3em;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .logo-place {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    left: 0;
    background: #F1F5F8;

    .image-place {
      width: 188px;
      height: 36px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-size: 1.7em;
      color: var(--primary);
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .sidebar-links {
    padding-top: 110px;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-shrink: 0;

    .sidebar-link {
      text-decoration: none;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      min-width: 170px;
      border-radius: 12px;
      margin-bottom: 15px;

      &.active {
        background-color: var(--primary);
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        color: var(--secondary);
        font-size: 1.3em;
        min-width: 125px;
        max-width: 145px;
        margin-left: 15px;

        &.active {
          color: #ffffff;
        }
      }
    }

    .side-bar-logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: red;
      background-color: rgba(255, 0, 0, 0.1);
      width: 100px;
      z-index: 10;
      padding: 20px 0 20px 5px;
      width: 210px;
      border-radius: 8px;

      img {
        margin-right: 15px;
      }

      &:hover {
        background-color: rgba(255, 0, 0, 0.4);
      }
    }
  }
}

.danger {
  color: rgb(236, 90, 90);
}
</style>
